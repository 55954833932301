<template>
  <div class="screen-box">
    <div ref="mapBox" style="transform-origin: 0 0; transition: 0.3s; position: relative;">
      <!-- div中间套用组件 -->
      <div style='height:1920px;width:1080px'>
        <BigScreenEntry />
      </div>
    </div>
  </div>
</template>
<script>
import BigScreenEntry from './entry.vue';
export default {
  data() {
    return { scale: 0, width: 1920, height: 1080 }
  },
  components: {
    BigScreenEntry
  },
  mounted() {
    this.setScale()
    window.addEventListener('resize', this.debounce(this.setScale))
  },
  methods: {
    getScale() {
      // 固定好16：9的宽高比，计算出最合适的缩放比
      const { width, height } = this
      const wh = window.innerHeight / height
      const ww = window.innerWidth / width
      return ww < wh ? ww : wh
    },

    setScale() {
      // 获取到缩放比例，设置它
      this.scale = this.getScale()
      //计算当前宽高
      const top = window.innerHeight - this.height * this.scale
      const left = window.innerWidth - this.width * this.scale
      //修改缩放比例，top left 上下留白距离
      if (this.$refs.mapBox) {
        this.$refs.mapBox.style.setProperty('transform', `scale(${this.scale})`)
        this.$refs.mapBox.style.setProperty('height', `${this.height}px`)
        this.$refs.mapBox.style.setProperty('width', `${this.width}px`)
        this.$refs.mapBox.style.setProperty('top', `${top / 2}px`)
        this.$refs.mapBox.style.setProperty('left', `${left / 2}px`)
      }
    },
    // 缩放（方法，响应时间）
    debounce(fn, delay) {
      const delays = delay || 500
      let timer
      return function () {
        const th = this
        const args = arguments
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(function () {
          timer = null
          fn.apply(th, args)
        }, delays)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.screen-box {
  // background-image: url('../img/map_bg.png');
  background-color: #000000;
  background-size: 100% 100%;
  box-shadow: 0 0 3px blue;
  position: relative;
  display: flex;
  flex-direction: column;
  transform: scale(var(--scale)) translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  // justify-content: center;
  // align-items: center;
  // overflow: auto;
}
</style>
