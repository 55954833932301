<template>
	<div id="app" class="flex">
		<!-- <div class="exchange-data">
			<el-checkbox v-model="isMockData" @change="changeMock"></el-checkbox>
		</div> -->
		<div class="title_bg">
			<div class="flex title">
				<div class="hunan-logo">
					<img src="@/assets/bigScreen/logo_hnyj.png" alt="" />
				</div>
				<div class="system-logo">
					<img src="@/assets/bigScreen/logo_title.png" alt="" />
				</div>
				<div class="flex time">
					<div style="margin-right: 15px; font-size: 26px">{{ time }}</div>
					<div>
						<p style="font-size: 12px">Date</p>
						<p style="font-size: 16px">{{ date }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="flex-1 content">
			<transition :name="transitionName">
				<keep-alive>
					<router-view />
				</keep-alive>
			</transition>
		</div>
		<template v-if="notLinePage">
			<div class="nav-bar"></div>
			<div class="route-container flex">
				<ul class="flex">
					<li v-for="(route, index) in routes" :key="index" :class="route.name === active ? 'flex active' : 'flex'">
						<router-link :to="route.path">
							<div>
								<img :src="route.meta.icon.default" alt="" />
								<br />
								<span>{{ route.meta.title }}</span>
							</div>
						</router-link>
					</li>
				</ul>
			</div>
		</template>
	</div>
</template>
<script>
import dayjs from "dayjs";
// import { mapState } from "vuex";

export default {
	name: "Tulufan",
	data() {
		return {
			isMockData: false,
			transitionName: "",
			time: "",
			date: "",
			active: "dashboard",
			routes: [
				{
					name: "part",
					path: "/bigScreen/part",
					meta: {
						title: "场站维保",
						icon: {
							active: require("@/assets/bigScreen/routeIcon/munu_icon_wb_active.png"),
							default: require("@/assets/bigScreen/routeIcon/munu_icon_wb.png")
						}
					}
				},
				{
					name: "line",
					path: "/bigScreen/line",
					meta: {
						title: "线路分析",
						icon: {
							active: require("@/assets/bigScreen/routeIcon/munu_icon_xl_active.png"),
							default: require("@/assets/bigScreen/routeIcon/munu_icon_xl.png")
						}
					}
				},
				{
					name: "dashboard",
					path: "/bigScreen/dashboard",
					meta: {
						title: "数据总览",
						icon: {
							active: require("@/assets/bigScreen/routeIcon/munu_icon_zl_active.png"),
							default: require("@/assets/bigScreen/routeIcon/munu_icon_zl.png")
						}
					}
				},
				{
					name: "customized",
					path: "/bigScreen/customized",
					meta: {
						title: "定制出行",
						icon: {
							active: require("@/assets/bigScreen/routeIcon/munu_icon_cx_active.png"),
							default: require("@/assets/bigScreen/routeIcon/munu_icon_cx.png")
						}
					}
				},
				{
					name: "charge",
					path: "/bigScreen/charge",
					meta: {
						title: "充电分析",
						icon: {
							active: require("@/assets/bigScreen/routeIcon/munu_icon_cd_active.png"),
							default: require("@/assets/bigScreen/routeIcon/munu_icon_cd.png")
						}
					}
				}
			]
		};
	},
	/* computed: {
		...mapState(["isMockData"])
	}, */
	computed: {
		notLinePage() {
			return this.$route.name !== "line";
		}
	},
	watch: {
		$route(to) {
			this.active = to.name;
			this.transitionName = "transform";
		}
	},
	// created() {},
	mounted() {
		setInterval(() => {
			this.time = dayjs().format("HH:mm:ss");
			this.date = dayjs().format("YYYY-MM-DD");
		}, 1000);
		this.active = this.$route.name;
	},
	methods: {
		changeMock(flag) {
			this.$store.commit("setMockFlag", flag);
		}
	}
};
</script>
<style lang="scss" scoped>
#app {
	position: fixed;
	width: 1920px;
	height: 1080px;
	flex-direction: column;
	.exchange-data {
		position: fixed;
		right: 20px;
		top: 20px;
	}
	img {
		vertical-align: top;
	}

	.content {
		flex: 1;
	}

	.title {
		justify-content: space-between;
		padding: 16px 8px 0 32px;
		width: 100%;
		height: 121px;
		background: url("~@/assets/bigScreen/top_title.png") no-repeat 0 0;
		background-size: 100% 100%;
		box-sizing: border-box;

		img {
			width: 100%;
		}

		.system-logo {
			width: 620px;
		}

		.hunan-logo {
			margin-top: 50px;
			width: 140px;
		}

		.time {
			margin-top: 50px;
			width: 167px;
			font-family: DS-Digital, sans-serif;
			color: #fff;
		}
	}

	.nav-bar {
		position: relative;
		z-index: 999;
		width: 100%;
		height: 57px;
		background: url("~@/assets/bigScreen/bottom_bg.png") no-repeat 15px 0 #01152d;
		background-size: 100% 100%;
	}
	.route-container {
		position: absolute;
		justify-content: center;
		bottom: 60px;
		width: 100%;
	}
	ul {
		justify-content: center;
		z-index: 1001;
	}

	li {
		justify-content: center;
		align-items: center;
		margin-right: 12px;
		width: 112px;
		height: 85px;
		font-size: 14px;
		background: url("~@/assets/bigScreen/menu_bg.png") no-repeat 0 0 transparent;
		background-size: 100% 100%;
		list-style: none;
		cursor: pointer;

		img {
			width: 36px;
			height: 36px;
		}

		a {
			text-align: center;
			text-decoration: none;
			color: #2b8dd9;
		}

		&.active {
			margin-top: -6px;
			width: 123px;
			height: 92px;
			font-size: 16px;
			background: url("~@/assets/bigScreen/menu_active.png") no-repeat 0 0 transparent;
			background-size: 100% 100%;

			img {
				width: 40px;
				height: 40px;
			}

			a {
				color: #fff;
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}
}
</style>
